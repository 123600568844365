import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
import AppAside from "@/layout/AppAside.vue";
Vue.use(VueRouter);
Vue.component("AppAside", AppAside);
import notfound from "@/views/not_found.vue";


const employee = () => import("@/views/employee.vue");
const project = () => import("@/views/project.vue");
const signup = () => import("@/views/Signup.vue");
const routes = [
  {
    path: "/signup",
    name: "signup",
    component: signup,
    meta: {
      title: "signup",
      requiresAuth: true,
      super_admin: false,
    },
  },
  {
    path: "/",
    name: "employee",
    component: employee,
    meta: {
      title: "employee",
      requiresAuth: true,
      super_admin: false,
    },
  },
  {
    path: "/project",
    name: "project",
    component: project,
    meta: {
      title: "project",
      requiresAuth: true,
      super_admin: false,
    },
  },

  {
    path: "*",
    name: "notfound",
    component: notfound,
    meta: {
      title: "notfound",
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
